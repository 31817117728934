<template>
	<div>
		<BannerEduOnline></BannerEduOnline>
		<div class="edu-online">
			<div class="edu-online__container">
				<EduOnline></EduOnline>
				<QRcode></QRcode>
			</div>
		</div>
	</div>
	
</template>

<script>
	import BannerEduOnline from '@/components/banner/BannerEduOnline.vue'
	import EduOnline from '@/components/index/EduOnline.vue'
	import QRcode from '@/components/QRcode.vue'
	export default {
		name: 'eduonline',
		components: {
			QRcode,
			EduOnline,
			BannerEduOnline
		},
	}
</script>

<style lang="scss">
	.edu-online {
		&__container {
			margin: 0 auto;
			max-width: 1140px;
		}
	}
</style>
